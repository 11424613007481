body {
  margin: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Regular"
}

.MuiFormLabel-root{
  background: white;
}

.no-col{
  padding: 0 !important;
}

@media(max-width:767px){
  .container{
    --bs-gutter-x: 0 !important;
  }
}

@font-face {
  font-family: "Regular";
  src: local("SF Pro Display"),
    url("./fonts/SFPRODISPLAYREGULAR.OTF") format("opentype");
}

.react-datepicker-wrapper {
  margin-top: 12px;
  margin-left: 10px;
  border-radius: 26px;
}

#root > div:nth-child(1) > div > div > div.sc-jaXxmE.gZQhch > div > div.container > div:nth-child(3) > div:nth-child(2) > div > input[type=text] {
  border-radius: 8px;
  border: 0
}

#root > div:nth-child(1) > div > div > div.sc-jaXxmE.gZQhch > div > div.container > div:nth-child(3) > div:nth-child(3) > div > input[type=text] {
  border-radius: 8px;
  border: 0;
}